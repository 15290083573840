/* eslint-disable @intlify/vue-i18n/no-unused-keys */

<i18n>
{
  "en": {
    "add": "Add",
    "button-tutorial": "Watch tutorial",
    "description": "Here you can import all the records you already have on another system. You have the option to download our template file and fill manually, or import any CSV file and map the columns.",
    "section-1-title": "Download the template file and fill manually",
    "card-1-label": "Template file of",
    "card-1-title": "Click here to download",
    "section-2-title": "Upload the file in .csv format",
    "next": "Next",
    "finish": "Finish",
    "step-2-title": "Relate columns",
    "step-2-description": "Map the wepipe columns according to the columns in your CSV file. Remember to select all required columns before submitting.",
    "wepipe-column": "Wepipe column",
    "csv-column": "Your file column",
    "required-column": "Required column",
    "enable-column": "Enable column",
    "step-3-title": "We are recording your data",
    "step-3-description": "Depending on the number of records you added, it may take a few minutes for the system to process. Keep using the system and we will send a notification to your email after finishing.",
    "notification-email": "Notifcation email",
    "map-columns-error-title": "Wasn't possible to continue",
    "map-columns-error-description": "Check if you fill all required fields and try again."
  },
  "pt-BR": {
    "add": "Adicionar",
    "button-tutorial": "Assistir tutorial",
    "description": "Aqui você poderá importar todos os registros que você já possui em outro sistema. Você tem a opção de baixar nosso arquivo modelo e preencher manualmente, ou importar qualquer arquivo CSV e mapear as colunas.",
    "section-1-title": "Baixe o arquivo modelo e preencha manualmente",
    "card-1-label": "Arquivo modelo de ",
    "card-1-title": "Clique aqui e faça o download",
    "section-2-title": "Faça o upload do arquivo no formato .csv",
    "next": "Continuar",
    "finish": "Finalizar",
    "step-2-title": "Relacionar colunas",
    "step-2-description": "Mapeie as colunas da wepipe de acordo com as colunas do seu arquivo CSV. Lembre-se de selecionar todas as colunas obrigatórias antes de enviar.",
    "wepipe-column": "Coluna da wepipe",
    "csv-column": "Coluna do seu arquivo",
    "required-column": "Coluna obrigatória",
    "enable-column": "Habilitar coluna",
    "step-3-title": "Estamos adicionando seus dados",
    "step-3-description": "Dependendo o número de registros que adicionou, talvez possa demorar alguns minutos para o sistema processar. Continue utilizando o sistema e enviaremos uma notificação para o seu email após finalizar.",
    "notification-email": "E-mail que receberá a notificação",
    "map-columns-error-title": "Não foi possível continuar",
    "map-columns-error-description": "Verifique se selecionou todos os campos obrigatórios e tente novamente."
  }
}
</i18n>

<template>
  <modal-action @close="closeModal">
    <template #view>
      <div class="modal" id="import_records">
        <we-icon-button class="close" icon="times" @click="closeModal" />
        <template v-if="step === 1">
          <div class="modal-body hide-scrollbar">
            <h1 class="we-title">{{ $t("add") }} {{ $root.$t(entity) }}</h1>
            <p class="description">
              {{ $t("description") }}
            </p>
            <div class="section-title">
              {{ $t("section-1-title") }}
            </div>
            <we-card-icon
              class="button"
              :label="$t('card-1-label')"
              :title="$t('card-1-title')"
              icon="download"
              @click="downloadImportTemplate"
            />
            <div class="section-title">{{ $t("section-2-title") }}</div>
            <we-csv-map @input="mapCsvInput" />
          </div>
          <div class="bottom">
            <we-button
              class="disabled only-desktop"
              target="_blank"
              href="https://www.youtube.com/watch?v=LJyAFxUDgZk&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=7"
              :text="$t('button-tutorial')"
              icon="play"
            />
            <we-button
              @click="finishFirstStep"
              :disabled="!userCsv"
              class="primary"
              :text="$t('next')"
              icon="arrow-right"
              :loading="btnLoading"
            />
          </div>
        </template>
        <template v-if="step === 2">
          <div class="modal-body hide-scrollbar">
            <div class="body-sticky">
              <h1 class="we-title">
                {{ $t("step-2-title") }}
              </h1>
              <p class="description">
                {{ $t("step-2-description") }}
              </p>
              <div class="map-columns-grid labels">
                <div class="column-labels"></div>
                <div class="column-labels">
                  {{ $t("wepipe-column") }}
                </div>
                <div class="column-labels">
                  {{ $t("csv-column") }}
                </div>
              </div>
            </div>
            <v-form ref="mapColumns" class="map-columns">
              <div
                class="map-columns-grid"
                v-for="header in importHeaders"
                :key="header.name"
                :cy="header.required ? 'required' : ''"
              >
                <v-tooltip top :disabled="!isDesktop">
                  <template v-slot:activator="{ on }">
                    <div class="action" v-on="on">
                      <v-checkbox
                        @change="updateImportArray"
                        v-model="header.enabled"
                        :readonly="header.required"
                        :color="header.required ? 'info' : 'primary'"
                        hide-details
                      />
                    </div>
                  </template>
                  <span>{{
                    header.required
                      ? $t("required-column")
                      : $t("enable-column")
                  }}</span>
                </v-tooltip>
                <we-input type="text" :placeholder="header.label" readonly />
                <we-input
                  type="select-search"
                  @input="updateImportArray"
                  v-model="header.related_column"
                  :items="headerItems"
                  :is-required="header.required"
                  :clearable="false"
                />
              </div>
            </v-form>
          </div>
          <div class="bottom">
            <we-button
              class="disabled only-desktop"
              target="_blank"
              href="https://www.youtube.com/watch?v=LJyAFxUDgZk&list=PLDT30RDBL9Lan3ap67XUhMB0wAvosYZd7&index=7"
              :text="$t('button-tutorial')"
              icon="play"
            />
            <we-button
              @click="finishSecondStep"
              class="primary"
              :text="$t('next')"
              icon="arrow-right"
              :loading="btnLoading"
            />
          </div>
        </template>
        <template v-if="step === 3">
          <div class="modal-body last-step">
            <h1 class="we-title">
              {{ $t("step-3-title") }}
            </h1>
            <p class="description">
              {{ $t("step-3-description") }}
            </p>
            <we-data-field
              :label="$t('notification-email')"
              :value="loggedUser.email"
            />
            <img
              src="@/assets/undraw/clock.svg"
              :alt="$t('step-3-title')"
              class="import-img"
            />
          </div>
          <div class="bottom right-action">
            <we-button
              @click="finishLastStep"
              class="primary"
              :text="$t('finish')"
              icon="arrow-right"
            />
          </div>
        </template>
      </div>
    </template>
  </modal-action>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      btnLoading: false,
      loading: false,
      entity: "",
      fileLoading: false,
      dragovered: false,
      userCsv: null,
      mappedData: null,
      step: 1
    };
  },
  computed: {
    importHeaders() {
      return this.$store.getters.getImportHeaders;
    },
    headerItems() {
      if (!this.userCsv?.headers) return [];

      return this.userCsv.headers;
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    }
  },
  methods: {
    ...mapActions(["importRequest", "importHeadersRequest"]),
    finishFirstStep() {
      this.updateImportArray();
      this.step++;
    },
    async finishSecondStep() {
      const validated = this.$refs.mapColumns.validate();

      if (!validated || !this.mappedData) {
        const snackBar = {
          title: this.$t("map-columns-error-title"),
          description: this.$t("map-columns-error-description"),
          status: "error"
        };

        this.$store.commit("setSnackBar", snackBar);
        this.$store.commit("addSnackBar");
        return;
      }
      this.btnLoading = true;

      const importResponse = await this.importRequest({
        entity: this.entity,
        data: this.mappedData
      });

      this.btnLoading = false;

      if (importResponse?.status === 201) {
        this.step++;
      }
    },
    finishLastStep() {
      this.step = 0;
      this.closeModal();
    },
    updateImportArray() {
      if (this.importHeaders?.length <= 0) return;
      if (this.userCsv?.data?.length <= 0) return;

      const headers = [...this.importHeaders];
      const data = [...this.userCsv.data];

      const resultArray = [];

      data.forEach(record => {
        let object = {};

        headers.forEach(header => {
          if (header.enabled && header.related_column) {
            object[header.name] = record[header.related_column];
          }
        });

        resultArray.push(object);
      });

      this.mappedData = resultArray;
    },
    defineImportEntity() {
      if (this.$route.name === "importContacts") {
        this.entity = "contacts";
      } else if (this.$route.name === "importCompanies") {
        this.entity = "companies";
      } else if (this.$route.name === "importDeals") {
        this.entity = "deals";
      } else if (this.$route.name === "importUsers") {
        this.entity = "users";
      } else if (this.$route.name === "importBills") {
        this.entity = "bills";
      } else if (this.$route.name === "importProducts") {
        this.entity = "products";
      }
    },
    closeModal() {
      if (this.$route.name === "importContacts") {
        this.$router.push({
          name: "contacts"
        });
      } else if (this.$route.name === "importCompanies") {
        this.$router.push({
          name: "companies"
        });
      } else if (this.$route.name === "importDeals") {
        this.$router.push({
          name: "deals"
        });
      } else if (this.$route.name === "importUsers") {
        this.$router.push({
          name: "users"
        });
      } else if (this.$route.name === "importBills") {
        this.$router.push({
          name: "bills"
        });
      } else if (this.$route.name === "importProducts") {
        this.$router.push({
          name: "productsConfigs"
        });
      }
    },
    downloadImportTemplate() {
      const url = `/template/wepipe-template-${this.entity}.csv`;
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", `wepipe-template-${this.entity}.csv`);
      document.body.appendChild(link);
      link.click();
    },
    mapCsvInput(value) {
      if (value?.headers?.length <= 0) return;
      if (this.importHeaders?.length <= 0) return;

      this.userCsv = value;

      this.importHeaders.map(header => {
        const hasHeader = this.userCsv.headers.find(e => e === header.name);

        if (hasHeader) header.related_column = hasHeader;

        return header;
      });
    }
  },
  async mounted() {
    this.defineImportEntity();
    await this.importHeadersRequest(this.entity);
  }
};
</script>

<style lang="scss">
#action_modal {
  .modal#import_records {
    max-width: 768px;
    height: 90%;
    display: flex;
    flex-flow: column;

    @include mobile {
      display: block;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      overflow-y: auto;
    }

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 2;
    }

    .modal-body {
      padding: 0 42px;
      z-index: 1;
      position: relative;
      height: fit-content;
      overflow-y: auto;
      flex: 1;
      @include mobile {
        height: fit-content;
        z-index: 0;
      }

      @include sm {
        padding: 30px 15px;
      }

      &.last-step {
        padding-bottom: 0;
        overflow-y: initial;
      }

      .body-sticky {
        position: sticky;
        top: 0;
        background: var(--background-1);
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -12px;
          height: 12px;
          width: 100%;
          background: var(--background-1);
          background: linear-gradient(
            180deg,
            var(--background-1) 0%,
            rgba(var(--background-1-rgb), 0) 100%
          );
        }

        @include sm {
          position: relative;
        }
      }

      h1.we-title {
        padding-top: 42px;
        font-size: 36px;
        font-weight: $semibold;
        line-height: 42px;
        color: var(--text-1);
        margin-bottom: 18px;

        @include sm {
          padding: 0;
        }
      }

      p.description {
        font-size: 16px;
        font-weight: $regular;
        margin-bottom: 32px;

        b {
          font-weight: $semibold;
        }
      }

      .section-title {
        font-size: 16px;
        font-weight: $semibold;
        margin-bottom: 16px;
      }

      .we-csv-map {
        margin-bottom: 40px;
      }

      .we-card-icon {
        width: fit-content;
        margin-bottom: 32px;
      }

      .import-img {
        width: 100%;
        max-width: 246px;
        position: absolute;
        bottom: -40px;
        pointer-events: none;

        @include sm {
          display: none;
        }
      }

      .we-data-field {
        margin-bottom: 40px;
      }

      .map-columns-grid {
        display: grid;
        grid-template-columns: 40px 1fr 1fr;
        gap: 12px;
        margin-bottom: 12px;

        &.labels {
          margin-bottom: 12px;
        }

        .column-labels {
          font-size: 13px;
          text-transform: uppercase;
          font-weight: $semibold;
          color: var(--text-2);
        }

        .v-input--checkbox {
          margin-top: 2px;
        }
      }
    }

    .bottom {
      padding: 0 42px;
      display: flex;
      justify-content: space-between;

      &.right-action {
        justify-content: flex-end;
      }

      @include sm {
        padding: 15px;
        display: block;
        height: fit-content;
      }

      .we-button {
        @include mobile {
          width: 100%;
        }
      }
    }
  }
}
</style>
